import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';

import { emitEvent } from './mrts.js';
import { INTRIVITY_LOGIN, INTRIVITY_LOGOUT } from './nav.js';

export class IntrivityHeader extends LitElement {

    static get styles() {
        return css`
            .header {
                width: 100%;
                align-self: flex-end;
                display: grid;
                grid-template-columns: 1fr 5fr 1fr 1fr;
            }
        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number },
            first: { type: String },
            printable: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.size = 1;
        this.m = -1;
        this.r = -1;
        this.t = -1;
        this.s = -1; 
    }

    render() {
        return html`
            <div style="display: flex; height: 130px; background-color: rgb(250, 166, 53);">
                <div class="header">
                    <div style="text-align: right; padding-left: 60px;">
                        <img style="height: 60px;" src="./images/intrivity.png" />
                    </div>    
                    <div></div>
                    <div @click=${(e) => { alert("Redirect user to main website contact page.");}} style="text-align: right; margin-right: 50px; position:relative; padding-right: 20px;"><span style='cursor: pointer; position:absolute; bottom:10px;'>contact</a></div> 
                    ${this.user_profile ? html`
                        <div @click=${(e)=>{
                            emitEvent(INTRIVITY_LOGOUT, {}, this);
                        }} style="text-align: right; margin-right: 50px; position:relative; padding-right: 20px;"><span style='cursor: pointer; position:absolute; bottom:10px;'>logout</a></div>
                    `: html`
                        <div @click=${(e)=> { emitEvent(INTRIVITY_LOGIN, {}, this);} } style="text-align: right; margin-right: 50px; position:relative; padding-right: 20px;"><span style='cursor: pointer; position:absolute; bottom:10px;'>login</a></div>
                    `}                          
            
    
                </div>
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-header')) customElements.define('intrivity-header', IntrivityHeader);
