import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import { emitEvent } from './mrts.js';

import './admin_domains.js';
import './admin_surveys.js';

// Manage Domains
export class IntrivityAdminConfig extends LitElement {

    static get styles() {
        return css`

        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            expanded: { type: Boolean }   
        };
    }

    constructor() {
        super();
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback(); 
    }
    
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`
            
            <div style="margin-bottom: 5px; width: 100%; color: white; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: black;">
                CONFIGURATION  
                ${this.expanded ? html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="white" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                `: html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="white" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                `}
            </div>            
            
            ${this.expanded ? html`
                <intrivity-admin-domains .user_profile=${this.user_profile}></intrivity-admin-domains>
                <intrivity-admin-surveys></intrivity-admin-surveys>
            `: html`

            `}
            
        `
    } 
 
}
if (!customElements.get('intrivity-admin-config')) customElements.define('intrivity-admin-config', IntrivityAdminConfig);
