import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import { getScore, getIcon, getColor, feedback_mapping } from './mrts.js';

import './behaviors.js';

export class IntrivityAttitudes extends LitElement {

    static get properties() {
        return {
            domain: { type: Object },
            domain_prefix: { type: String },
            report: { type: Object },
            group_report: { type: Object },
            expanded: { type: Boolean },
            activated: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.expanded = false;
        this.activated = false;
        this.getRawScore = this.getRawScore.bind(this);
        this.isAttitudesCompleted = this.isAttitudesCompleted.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    getRawScore(i) {
        return this.report[this.domain.code].attitudes[i].average;
    }
    
    getTeamScore(i) {
        let groups = [];
        if (this.group_report) {
            let max = 1;
            for (let key in this.group_report[this.domain.code].attitudes[i].responses) {
                if (this.group_report[this.domain.code].attitudes[i].responses.hasOwnProperty(key)) {
                    let group = {};
                    group.score = Number(key).toFixed(1);
                    group.size = this.group_report[this.domain.code].attitudes[i].responses[key].length;
                    if (max < group.size) max = group.size;
                    // weight should be 5 - 20 => 15*x/maxx + 4
                    group.weight = this.group_report[this.domain.code].attitudes[i].responses[key].length * 5;
                    groups.push(group);
                }
            }
            for (let i =0; i < groups.length; i++) {
                groups[i].max = max;
            }
            //let group = {};
            //group.score = this.group_report[this.domain.code].attitudes[i].average;
            //group.size = this.group_report.users.length;
            //group.weight = 5; //??
            //groups.push(group);
        }
        return groups;
    }
    
    isAttitudesCompleted() {
        return this.report[this.domain.code].attitudes.length > 0;
    }
    
    render() {
        return html`
        
            ${this.activated || (this.report && this.isAttitudesCompleted) ? html`
                
                <div style="margin-bottom: 5px; width: 100%; color: white; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: black;">
                    ATTITUDES  
                    ${this.expanded ? html`
                        <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="white" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                    `: html`
                        <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="white" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                    `}
                </div>
        
            ` : html`

                <div style="margin-bottom: 5px; width: 100%; color: white; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: lightgray;">
                    ATTITUDES <span style="color: black;">ACTIVATE</span>
                </div>
        
            `}
        
            ${this.expanded ? html`
                <div style="width: 100%;">
                    ${this.report[this.domain.code].attitudes.length > 0 ? html`
                    ${this.domain.attitudes.map((attitude, i) => 
                        html`
                        
                            <div style="display: grid; grid-template-columns: 1fr 1fr; width: 100%; background-color:  white; padding: 10px; padding-top: 15px; padding-left: 30px; padding-right: 30px;">
                                <div style="margin: 10px;">
                                    <div style="color: ${this.domain.color}; font-size: 1.6em; margin-bottom: 20px;">${attitude.name.toUpperCase()}</div>
                                    <div style="margin-bottom: 20px;">${attitude.definition}</div>      
                                    <div style="margin-bottom: 20px;">SAMPLE ITEM: ${attitude.sample}</div>
                                </div>
                                <div style="margin: 10px; padding-right: 20px;">
                                     <div><intrivity-likert-report .right_text=${attitude.right_label} .left_text=${attitude.left_label} .you=${this.getRawScore(i)} .groups=${this.getTeamScore(i)} .color=${this.domain.color}></intrivity-likert-report></div>
                                     <div style="margin: 5%;">${attitude.meaning[feedback_mapping[getScore(this.getRawScore(i)) - 1]]}</div>
                                 </div>
                            </div>
                        `
                    )}
                    <div style="padding-right: 30px; background-color: white; width: 100%; text-align: right;"><intrivity-likert-scale-legend></intrivity-likert-scale-legend></div>                        
                    `: html`<div style="padding-left: 30px;">No data for user.</div>`}
                </div>    
            `: html``}
        `
    } 
 
}
if (!customElements.get('intrivity-attitudes')) customElements.define('intrivity-attitudes', IntrivityAttitudes);
