import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import { getScore, getIcon, getColor,feedback_mapping } from './mrts.js';

export class IntrivityBehaviors extends LitElement {

    static get properties() {
        return {
            characteristic: { type: Object },
            characterestic_index: { type: Number },
            domain: {type: Object },
            domain_prefix: { type: String },
            report: { type: Object },
            group_report: { type: Object },
            expanded: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.getRawScore = this.getRawScore.bind(this);
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    getRawScore(i) {
        console.log(this.report[this.domain.code].level_two[this.characterestic_index].behaviors[i].average);
        return this.report[this.domain.code].level_two[this.characterestic_index].behaviors[i].average;
    }
    
    getTeamScore(i) {
        let groups = [];
        if (this.group_report) {
            let max = 1;
            for (let key in this.group_report[this.domain.code].level_two[this.characterestic_index].behaviors[i].responses) {
                if (this.group_report[this.domain.code].level_two[this.characterestic_index].behaviors[i].responses.hasOwnProperty(key)) {
                    let group = {};
                    group.score = Number(key).toFixed(1);
                    if (max < group.size) max = group.size;
                    group.size = this.group_report[this.domain.code].level_two[this.characterestic_index].behaviors[i].responses[key].length;
                    group.weight = this.group_report[this.domain.code].level_two[this.characterestic_index].behaviors[i].responses[key].length * 5;
                    groups.push(group);
                }
            }
            for (let i =0; i < groups.length; i++) {
                groups[i].max = max;
            }
            //let group = {};
            //group.score = this.group_report[this.domain.code].level_two[this.characterestic_index].behaviors[i].average;
            //group.size = this.group_report.users.length;
            //group.weight = 5; //??
            //groups.push(group);
        }
        return groups;
    }   
    
    render() {
        return html`
       
            <div style="background-color: ${this.expanded ? 'whitesmoke': 'white'}; width: 100%; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px;">
                SHOW BEHAVIORS
                ${this.expanded ? html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                `: html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                `}
            </div>
        
            ${this.expanded ? html`
                ${this.characteristic.behaviors.map((behavior, j)=> html`
                <div style="display: grid; grid-template-columns: 1fr 1fr; width: 100%; background-color:  whitesmoke; padding: 10px; padding-top: 15px; padding-left: 30px; padding-right: 30px;">
                    <div style="margin: 10px;">
                        <div style="color: ${this.domain.color}; font-size: 1.6em; margin-bottom: 20px;">${behavior.name.toUpperCase()}</div>
                        <div style="margin-bottom: 20px;">${behavior.definition}</div>      
                        <div style="margin-bottom: 20px;">SAMPLE ITEM: ${behavior.sample}</div>
                    </div>
                    <div style="margin: 10px; padding-right: 20px;">
                         <div><intrivity-likert-report  .right_text=${behavior.right_label} .left_text=${behavior.left_label} .you=${this.getRawScore(j)} .color=${this.domain.color} .groups=${this.getTeamScore(j)}></intrivity-likert-report></div>
                         <div style="margin: 5%;">${behavior.meaning[feedback_mapping[getScore(this.getRawScore(j)) - 1]]}</div>
                     </div>
                </div>
                `)}
                <div style="padding-right: 30px; background-color: whitesmoke; width: 100%; text-align: right;"><intrivity-likert-scale-legend></intrivity-likert-scale-legend></div>
            `: html``}
        `
    } 
 
}
if (!customElements.get('intrivity-behaviors')) customElements.define('intrivity-behaviors', IntrivityBehaviors);
