import { html, css, LitElement, unsafeCSS } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";  

import { doc, setDoc, getDoc, getDocs, collection, collectionGroup, query, where, addDoc, getFirestore } from "firebase/firestore";
 
import './badge.js';
import './user_profile_field_editor.js';
import '@material/mwc-dialog';

import { db, user, defaultGroup, emitEvent, getScore } from './mrts.js';
import { INTRIVITY_LOAD_GROUPS } from './nav.js';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export class IntrivityUser extends LitElement {

    static get styles() {
        return css`
            .user_layout {
                display: grid;
                grid-template-columns: 1fr 4fr 1fr;
                grid-gap: 5px;
                margin-top: 30px;
            }
            .user_field {
                color: #424141;
                font-size: 2.0vmax;
                margin-bottom: 20px;
            }
            .user_edit {
                cursor: pointer;
                color: #424141;
                white-space: nowrap; 
                margin-left: 10px; 
                font-size: 0.8vmax;
            }
            .user_config {
                color: #424141;
                margin: 20px;
                padding: 20px;
                grid-column: 1 / span 3;
                background-color: #F6F6F6;
                font-size: 1.5vmax;
             
            }
            .user_comparisons {
                font-size: 1.5vmax;
                margin-left: 60px;
                margin-bottom: 20px;
            }
        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            report: { type: Object },
            group_code: { type: String },
            group_reports: { type: Array },
            group: { type: Object },
            display_group: { type: Object }
        };
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("resize", (e)=> { this.requestUpdate(); });
        console.log("IntrivityUser loaded. Emit load groups event.");
        emitEvent( INTRIVITY_LOAD_GROUPS, {}, this );
        
    }
    
    constructor() {
        super(); 
        this.getRegistrationGroup = this.getRegistrationGroup.bind(this);
        this.sendToSurvey = this.sendToSurvey.bind(this);
    }

    async sendToSurvey() {
        // https://www.research.net/r/LWV5WDJ?userid=joseph.conder@gmail.com&groupid=hwCXbf1U9mmWvdyQ1pEI
        if (this.user_profile.group) {
            if (!this.group) {
                const docRef = doc(db, "groups", this.user_profile.group);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.group = docSnap.data(); 
                }   
            }
            if (this.group) {
                if (confirm("This link will take you to the survey.  Are you sure you want to proceed?")) {
                    let url = 'https://www.research.net/r/';
                    url += this.group.survey_monkey_key;
                    url += '?userid=';
                    url += user.email;
                    url += '&groupid=';
                    url += this.user_profile.group;
                    window.location.href = url;
                }
            }
        }
    }
    
    async getRegistrationGroup() {
        this.group = undefined;
        const invitationQuery = query(collectionGroup(db, 'invitations'), where("code", "==", this.group_code));
        const invitationSnapshot = await getDocs(invitationQuery);
        let invitation = {};
        if (invitationSnapshot.size > 0) {
            invitationSnapshot.forEach((invDoc) => {
                console.log(invDoc.id, ' => ', invDoc.data());
                invitation = invDoc.data();
            });
            if (invitation.status && invitation.status == "claimed") {
                alert("This Intrivity code has already been claimed.");
            } else {
                if (invitation.group_uuid) {
                    const groupRef = doc(db, "groups", invitation.group_uuid);
                    const groupSnap = await getDoc(groupRef);
                    if (groupSnap.exists()) {
                        console.log(groupSnap.id, ' => ', groupSnap.data());
                        this.group = groupSnap.data(); 
                    } else {
                        alert("Could not find group reference.");
                    }
                }
            }
        } else {
            const queryGroup = query(collection(db, "groups"), where("code", "==", this.group_code));
            const groupSnapshot = await getDocs(queryGroup);
            if (groupSnapshot.size > 0) {
                groupSnapshot.forEach((doc) => {
                    console.log(doc.id, ' => ', doc.data());
                    this.group = doc.data();
                });
            } else {
                alert("There was no match for this Intrivity code.");
            }
        }
        if (this.group) {
            console.log(this.group);
            try {
                this.user_profile.group = this.group.uuid;
                if (this.user_profile.groups) {
                    if (!this.user.profile.groups.includes(this.group.uuid)) {
                        this.user_profile.groups.push(this.group.uuid);
                    }
                } else {
                    this.user_profile.groups = [];
                    this.user_profile.groups.push(this.group.uuid);
                    if (invitation && invitation.teams && invitation.teams.length > 0) {
                        for (let i=0; i < invitation.teams.length; i++) {
                            this.user_profile.groups.push(invitation.teams[i]);
                        }
                    }
                }
                await setDoc(doc(db, "users", user.email), this.user_profile, { merge: true });
            } catch (err) {
                this.user_profile.group = undefined;
                console.log(err);
            }
            // Need to reprocess group?
            if (this.report) {
                try {
                    this.group.status = 'process';
                    await setDoc(doc(db, "groups", this.group.uuid), this.group, { merge: true });
                } catch (err) {
                    console.log(err);
                }                
            }
        }      
    }
    
    
    render() {
        return html`
            <div class="user_layout">

                <div style="margin-left: 20px;">
                    <img  style="width: ${window.innerWidth < 600 ? '100px': '170px'}; height: ${window.innerWidth < 600 ? '100px': '170px'};" src="${this.user_profile.image}" />
                    <user-profile-field-editor .user_profile=${this.user_profile} field="image" .value=${this.user_profile.image}></user-profile-field-editor>
                </div>
        
                <div style="width: 100%;">
                    <div class="user_field">
                        ${this.user_profile.displayName}
                        <span class="user_edit">edit name</span>
                    </div>
                    <div class="user_field">
                        ${this.user_profile.email}
                    </div>
                    <div class="user_field">
                        <div>
                            <user-profile-field-editor show_value="true" .user_profile=${this.user_profile} field="organization" .value=${this.user_profile.organization}></user-profile-field-editor>
                        </div>
                        <div>
                            <user-profile-field-editor show_value="true" .user_profile=${this.user_profile} field="title" .value=${this.user_profile.title}></user-profile-field-editor>
                        </div>
                    </div>
                </div>
        
                <intrivity-badge style="aspect-ratio: 1; cursor: grab; text-align: center; margin-right: 20px;" @click=${(e) => {
                    if (this.report) {
                        if (confirm("Download your MRTS badge?")) {
                            htmlToImage.toPng(e.target)
                                .then(function (dataUrl) {
                                    var link = document.createElement('a');
                                    link.download = 'mrts-badge.png';
                                    link.href = dataUrl;
                                    link.click();
                                });
                        }
                    }
                }} size="${window.innerWidth < 600 ? '1': '1.7'}" m="${this.report ? getScore(this.report.m.average): -1}" r="${this.report ? getScore(this.report.r.average): -2}" t="${this.report ? getScore(this.report.t.average): -3}" s="${this.report ? getScore(this.report.s.average): -4}" type="square"></intrivity-badge>

                ${this.report ? html`
                    <div class="user_config">
                        My assessment report: Standard <span @click=${(e)=>{ alert("Take user to active comprehensive report workflow.");}} class="user_edit">activate comprehensive report</span>
                    </div>
                `: html``}
        
                ${ this.user_profile.group ? html`
                    ${this.report ? html``: html`
                        <div class="user_config">
                            ${this.user_profile.processing && this.user_profile["processing"] == true ? html`
                                <div>Your survey is being processed.</div>
                            `: html`
                                <mwc-button style="vertical-align: bottom;" outlined dense @click=${this.sendToSurvey}>Take the Survey</mwc-button>
                            `}
                        </div>
                    `}
                    
                `: html`
                    <div class="user_config">
                        <div>Your account is not associated with a group.</div>
                        <div>
                            To take the Intrivity survey, please enter an Intrivity code:  
                        </div> 
                        <div>
                            <mwc-textfield @change=${(e) => { this.group_code = e.target.value; } } label="Intrivity Code"></mwc-textfield>
                            <mwc-button style="vertical-align: bottom;" outlined dense @click=${this.getRegistrationGroup}>Submit</mwc-button>
                        </div>
                    </div>
                `}
                
                ${ this.group_reports && this.group_reports.length > 0 ? html`
                    <div class="user_config">
                        <div style="margin-bottom: 25px;">
                            My comparison groups:
                        </div>
                        <div class="user_comparisons">
                            ${this.group_reports.map((grp)=>html`
                                <div style="cursor: pointer;" @click=${(e)=> { 
                                    console.log(grp);
                                    this.display_group = grp; 
                                    let selector = '.groupDialog';
                                    this.shadowRoot.querySelector(selector).open = true; 
                                }}>${grp.title}</div>
                            `)}
                        </div>
                    </div>
                `: html``}

            </div>
            <mwc-dialog class="groupDialog" scrimClickAction="">
        
                ${this.display_group ? html`
                <div style="font-size: larger;">${this.display_group.title ? this.display_group.title: ''}</div>
                <div style="font-size: smaller">Team Size: ${this.display_group.users.length}</div>
        
                <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr 1fr 2fr; grid-gap: 5px;">
                    <div style="font-weight: bolder"></div>
                    <div style="font-weight: bolder">Last Name</div>
                    <div style="font-weight: bolder">First Name</div>
                    <div style="font-weight: bolder">Title</div>
                    ${this.display_group.users.map((user) => html`
                        <div style="text-align: center;">
                            ${user.image ? html`<img src=${user.image} width="40px" />`: html``}
                        </div>
                        <div style="font-size: smaller">${user.lastName ? user.lastName: ''}</div>
                        <div style="font-size: smaller">${user.firstName ? user.firstName: ''}</div>
                        <div style="font-size: smaller">${user.title ? user.title: ''}</div>            
                    `)}
                </div>
                
                <div style="width: 100%; text-align: center;">
                    <div style="cursor: pointer; padding: 5px; padding-left: 15px; padding-right: 15px; font-weight: bolder; display: inline; background-color: rgb(87, 130, 133); color: white; margin-top: 10px;">GO TO TEAM</div>
                </div>
        
                `: html`` }
            </mwc-dialog>
        `
    } 
 
}
if (!customElements.get('intrivity-user')) customElements.define('intrivity-user', IntrivityUser);
