import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';
import './domain.js';

import { getScore, emitEvent } from './mrts.js';
import { INTRIVITY_NAV_SELECT } from './nav.js';

export class IntrivityProfile extends LitElement {

    static get styles() {
        return css`
            .teams { 
                max-width: 600px; margin: auto;
            }
            .group_list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
            }
            .group_badge {
                display: grid;
                grid-template-columns: 60px 1fr;
            }
            .group_profile {
                display: grid;
                grid-template-columns: 30px 10px 1fr;
            }        
        `;
    }
    
    static get properties() {
        return {
            selected: { type: String },
            space_domain: { type: Object },
            message_domain: { type: Object },
            relationship_domain: { type: Object },
            time_domain: { type: Object },   
            group_report: { type: Object },
            report: { type: Object },
            size: { type: Number }
        };
    }

    constructor() {
        super(); 
        this.size = 1;
        this.getDomain = this.getDomain.bind(this);
    }
    
    getDomain(domain) {
        
        this.selected = domain;      
        
    }

    render() {
        return html`
        
            <div style="margin-top: 50px;  width: 100%;">

                <div style="max-width: 800px; margin: auto; display: grid; grid-gap: 0px; grid-template-columns: 1fr 4fr 8fr 4fr 1fr;">

                    <div></div>
                    <div style="grid-column: 2 / span 4;">
                        <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "message") { this.selected = undefined; } else {  this.getDomain("message"); } }} height="75px;" src="./images/${this.message_domain.image}">
                    </div>

                    ${this.selected ? html`
            
                        <div style="display: flex; align-items: flex-end;">
                            <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "relationship") { this.selected = undefined; } else {  this.getDomain("relationship"); } }} height="75px; margin-right: 10px;" src="./images/${this.relationship_domain.image}">
                        </div>
        
                        ${this.selected === "message" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.m.average}" .domain=${this.message_domain}></intrivity-domain>
                        `: html``}
        
                        ${this.selected === "relationship" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.r.average}" .domain=${this.relationship_domain}></intrivity-domain>
                        `: html``}
        
                        ${this.selected === "space" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.s.average}" .domain=${this.space_domain}></intrivity-domain>
                        `: html``}
        
                        ${this.selected === "time" ? html`
                            <intrivity-domain style="grid-column: 2 / span 3;" raw="${this.report.t.average}" .domain=${this.time_domain}></intrivity-domain>
                        `: html``}
        
        
                        <div>
                            <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "space") { this.selected = undefined; } else {  this.getDomain("space"); } }} height="70px;" src="./images/${this.space_domain.image}">
                        </div>



                    `: html`
                        <div></div>
                        <div @click=${(e)=>{ if (this.selected === "message") { this.selected = undefined; } else {  this.getDomain("message"); } }} style="cursor: pointer; border: 10px solid ${this.message_domain.color}; display: flex; align-items: flex-end; height: 150px; color: ${this.message_domain.color};">
                            <div style="font-size: 22px; margin: 10px; width: 100%; text-align: center;">
                                <div style="margin-bottom: 20%; font-size: 34px; font-weight: bolder;">${getScore(this.report.m.average)}</div>
                                <div style="font-size: 22px; width: 100%; text-align: right;">Message Domain</div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: flex-end; color: white; background-color: ${this.message_domain.color};">
                            <div style="margin: 10px; width: 50%;">The indirectness and implicitness of a message.</div>
                        </div>
                        <div @click=${(e)=>{ this.getDomain("space"); }} style="cursor: pointer; border: 10px solid ${this.space_domain.color}; display: flex; align-items: flex-end; height: 150px; color: ${this.space_domain.color};">
                            <div style="font-size: 22px; margin: 10px; width: 100%; text-align: center;">
                                <div style="margin-bottom: 20%; font-size: 34px; width: 100%; font-weight: bolder; text-align: center;">${getScore(this.report.s.average)}</div>
                                <div style="font-size: 22px; width: 100%; text-align: left;">Space Domain</div>
                            </div>                        
                        </div>
                        <div><img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "space") { this.selected = undefined; } else {  this.getDomain("space"); } }} height="70px;" src="./images/${this.space_domain.image}"></div>

                        <div></div>
                        <div style="display: flex; align-items: flex-end; text-align: right; height: 300px; color: white; background-color: ${this.relationship_domain.color};">
                            <div style="margin: 10px;">The role of status and the nature of relationship in communication.</div>
                        </div>
                        <div style="text-align: center; font-size: 22px; margin: auto;">
                            <div style="margin: 10px;">Select a domain to see your personalized results.</div>
                        </div>
                        <div style="color: white; background-color: ${this.space_domain.color};">
                            <div style="margin: 10px;">The communication cues in the physical, auditory, and vocal space.</div>
                        </div>
                        <div></div>

                        <div style="display: flex; align-items: flex-end;">
                            <img style="cursor: pointer;" @click=${(e)=>{ this.getDomain("relationship"); }} height="75px; margin-right: 10px;" src="./images/${this.relationship_domain.image}">
                        </div>
                        <div @click=${(e)=>{ this.getDomain("relationship"); }} style="cursor: pointer; border: 10px solid ${this.relationship_domain.color}; height: 150px; color: ${this.relationship_domain.color};">
                            <div style="font-size: 22px; margin: 10px; text-align: center;">
                                <div style="font-size: 22px; text-align: right; width: 100%;">Relationship Domain</div>
                                <div style="margin-top: 20%; font-size: 34px; font-weight: bolder;">${getScore(this.report.r.average)}</div>
                            </div>               
                        </div>
                        <div style="width: 100%; text-align: right; color: white; background-color: ${this.time_domain.color};">
                            <div style="float: right; margin: 10px; width: 60%;">The communication cues in the way one moves through time.</div>
                        </div>
                        <div @click=${(e)=>{ this.getDomain("time"); }} style="cursor: pointer; border: 10px solid ${this.time_domain.color}; height: 150px; color: ${this.time_domain.color};">
                            <div style="font-size: 22px; margin: 10px; text-align: center;">
                                <div style="font-size: 22px; text-align: left; width: 100%;">Time Domain</div>
                                <div style="margin-top: 20%; font-size: 34px; font-weight: bolder;">${getScore(this.report.t.average)}</div>
                            </div>
                        </div>
                        <div></div>
                    `}
        

                    <div style="grid-column: 1 / span 4; text-align: right;">
                        <img style="cursor: pointer;" @click=${(e)=>{ if (this.selected === "time") { this.selected = undefined; } else {  this.getDomain("time"); } }} height="65px;" src="./images/${this.time_domain.image}">
                    </div>
                    <div></div>

                </div>       
        
                ${this.selected ? html`
                    <div style="padding: 20px;"></div>
                    <div style="width: 100%; text-align: center;">
                        <div @click=${(e)=> {
                            emitEvent(INTRIVITY_NAV_SELECT, { "selection": this.selected }, this);
                        }} style=" cursor: pointer; padding: 5px; padding-left: 15px; padding-right: 15px; font-weight: bolder; display: inline; background-color: rgb(87, 130, 133); color: white;">GO TO DETAILS</div>
                        <div @click=${(e)=>{ this.selected = undefined; this.requestUpdate();}} style="cursor: pointer; padding: 5px; padding-left: 15px; padding-right: 15px; font-weight: bolder; display: inline; background-color: rgb(87, 130, 133); color: white;">BACK TO SUMMARY</div>     
                    </div>
                `: html``}
        
                <div class="teams">
                ${this.group_report ? html`

                    <h3>${this.group_report.title}</h3>

                    <div class="group_list">

                        ${this.group_report.users.sort((a, b) => {
                            if (a.lastName + ', ' + a.firstName < b.lastName + ', ' + b.firstName) {
                              return -1;
                            }
                            if (a.lastName + ', ' + a.firstName > b.lastName + ', ' + b.firstName) {
                              return 1;
                            }
                            return 0;
}                       ).map((user=> html`

                            ${this.selected === "message" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.m)}" .domain=${this.message_domain}></intrivity-domain>
                                       <div style="color: ${this.message_domain.color}">${getScore(user.profile.m)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>                            
                            `: html``}

                            ${this.selected === "relationship" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.r)}" .domain=${this.relationship_domain}></intrivity-domain>
                                       <div style="color: ${this.relationship_domain.color}">${getScore(user.profile.r)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>       
                            `: html``}

                            ${this.selected === "space" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.s)}" .domain=${this.space_domain}></intrivity-domain>
                                       <div style="color: ${this.space_domain.color}">${getScore(user.profile.s)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>                            
                            `: html``}

                            ${this.selected === "time" ? html`
                                <div class="group_profile">
                                       <intrivity-domain profile="true" raw="${getScore(user.profile.t)}" .domain=${this.time_domain}></intrivity-domain>
                                       <div style="color: ${this.time_domain.color}">${getScore(user.profile.t)}</div>
                                       <div style="margin-left: 10px;">${user.lastName}, ${user.firstName}</div>   
                               </div>                            
                            `: html``}
        
                            ${this.selected ? html``: html`

                                <div class="group_badge">
                                   <intrivity-badge-plain .m=${getScore(user.profile.m)} .r=${getScore(user.profile.r)} .s=${getScore(user.profile.s)} .t=${getScore(user.profile.t)} .size=${this.size}></intrivity-badge-plain>
                                   <div style="margin-left: 5px;">
                                       <div>${user.lastName}, ${user.firstName}</div>
                                       <div style="font-size: smaller; text-align: center; display: inline-grid; grid-template-columns: 1fr 1fr 1fr 1fr;">
                                           <div>M</div><div>R</div><div>T</div><div>S</div>
                                           <div>${getScore(user.profile.m)}</div>
                                           <div>${getScore(user.profile.r)}</div>
                                           <div>${getScore(user.profile.t)}</div>
                                           <div>${getScore(user.profile.s)}</div>
                                       </div>
                                   <div>
                               </div>
        
                            `}
        
                        ` ))}       

                    </div>
        
                `: html``}    
                </div>
        

            </div>
        
        `
    } 
 
}
if (!customElements.get('intrivity-profile')) customElements.define('intrivity-profile', IntrivityProfile);
