import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';

import { emitEvent, user } from './mrts.js';
import { INTRIVITY_LOGIN, INTRIVITY_NAV_SELECT, INTRIVITY_GROUP_SELECT } from './nav.js';

export class IntrivityNameBanner extends LitElement {

    static get styles() {
        return css`
            .banner {
                width: 100%;
                border-bottom: 7px solid rgb(250, 166, 53);
                display: grid;
                grid-template-columns: 3fr 1fr ;
            }
            .banner_badge {
                display: grid;
                grid-template-columns: 50px 1fr ;
                margin: auto;
                cursor: pointer;
            }
        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            group_report: { type: Object },
            group_reports: { type: Array },
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number }
        };
    }

    constructor() {
        super();
        this.size = 1;
    }

    render() {
        return html`
            <div class="banner">
                <div>
                    <h1 style="font-size: 2.1vmax; margin-left: 20px;">
                        ${user ? html`
                            Welcome Back, ${this.user_profile.firstName ? this.user_profile.firstName: this.user_profile.displayName}!
                        `: html`
                            Welcome!  Please log in to My Intrivity!
                        `}
                    </h1>
                    ${this.group_reports ? html`
                        <div style="margin-left: 20px;">
                            Compare with 
                            <select @change=${(e)=>{ emitEvent(INTRIVITY_GROUP_SELECT, JSON.parse(e.target.value), this); }}>
                                ${this.group_reports.map((report) => html`
                                    <option value=${JSON.stringify(report)}>${report.title}</option>
                                `)}
                            </select>
                        </div>
                    `: html``}
                </div>
                    
                ${ this.m && this.r && this.t && this.s ? html`
                        <div class="banner_badge" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "profile" }, this);
                                } }>
                            <intrivity-badge-plain .m=${this.m} .r=${this.r} .s=${this.s} .t=${this.t} .size=${this.size}></intrivity-badge-plain>
                            <div style="margin-left: 5px; font-size: larger;">
                                M R T S<br />
                                ${this.m} ${this.r} ${this.t} ${this.s}
                            <div>
                        </div>
                `: html``}
                
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-name-banner')) customElements.define('intrivity-name-banner', IntrivityNameBanner);
