import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import './behaviors.js';
import { getScore, getIcon, getColor, feedback_mapping } from './mrts.js';

export class IntrivityCharacteristics extends LitElement {

    static get properties() {
        return {
            domain: { type: Object },
            domain_prefix: { type: String },
            report: { type: Object },
            group_report: { type: Object },
            expanded: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.getRawScore = this.getRawScore.bind(this);
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    getRawScore(i) {
        console.log(this.report[this.domain.code].level_two[i].average);
        return this.report[this.domain.code].level_two[i].average;
    }
    
    getTeamScore(i) {
        let groups = [];
        if (this.group_report) {
            let max = 1;
            for (let key in this.group_report[this.domain.code].level_two[i].responses) {
                if (this.group_report[this.domain.code].level_two[i].responses.hasOwnProperty(key)) {
                    let group = {};
                    group.score = Number(key).toFixed(1);
                    if (max < group.size) max = group.size;
                    group.size = this.group_report[this.domain.code].level_two[i].responses[key].length;
                    group.weight = this.group_report[this.domain.code].level_two[i].responses[key].length * 5;
                    groups.push(group);
                }
            }
            for (let i=0; i < groups.length; i++) {
                groups[i].max = max;
            }
            //group.score = this.group_report[this.domain.code].level_two[i].average;
            //group.size = this.group_report.users.length;
            //group.weight = 5; //??
            //groups.push(group);
        }
        return groups;
        //return JSON.stringify(groups);
    }
    
    render() {
        return html`
            <div style="margin-bottom: 5px; width: 100%; color: black; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                CHARACTERISTICS  
                ${this.expanded ? html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                `: html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                `}
            </div>
        
            ${this.expanded ? html`
                <div style="width: 100%;">
                    ${this.domain.level_two.map((characteristic, i) => 
                        html`
                            <div style="display: grid; grid-template-columns: 1fr 2fr; width: 100%; margin: 20px;">
                                <div style="margin: 20px;">
                                    <div style="color: ${this.domain.color}; font-size: 1.6em; margin-bottom: 15px;">
                                        ${characteristic.name.toUpperCase()}     
                                    </div>
                                    <div>${characteristic.description}</div>
                                </div>
                                <div style="margin: 20px;">
                                    <div><intrivity-likert-report .right_text=${characteristic.right_label} .left_text=${characteristic.left_label} .you="${this.getRawScore(i)}" .groups=${this.getTeamScore(i)} .color=${this.domain.color}></intrivity-likert-report></div>
                                    <div style="margin: 5%;">${characteristic.feedback[feedback_mapping[getScore(this.getRawScore(i)) - 1]]}</div>
                                </div>
                            </div>
                         
                            <div style="margin: 10px;">
                                <intrivity-behaviors .group_report=${this.group_report} .characteristic=${characteristic} .characterestic_index="${i}" .domain=${this.domain} .domain_prefix=${this.domain_prefix} .report=${this.report}></intrivity-behaviors>
                            </div>
        
                            <hr />
                        `
                    )}
                    <div style="padding-right: 30px; width: 100%; text-align: right;"><intrivity-likert-scale-legend></intrivity-likert-scale-legend></div>
            
                </div>    
            `: html``}
        `
    } 
 
}
if (!customElements.get('intrivity-characteristics')) customElements.define('intrivity-characteristics', IntrivityCharacteristics);
