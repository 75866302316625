import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import './domain-details.js';

export class IntrivityDomain extends LitElement {

    static get styles() {
        return css`
        `;
    }
    
    static get properties() {
        return {
            domain: { type: Object },
            raw: { type: Number },
            profile: { type: Boolean }
        };
    }

    constructor() {
        super(); 
        this.raw = 0;
        this.getScore = this.getScore.bind(this);
        this.getColor = this.getColor.bind(this);
        this.getDisplayScore = this.getDisplayScore.bind(this);
        this.getScoreText = this.getScoreText.bind(this);
        this.getIcon = this.getIcon.bind(this);
    }

    getScore() {
        if (this.raw < 1.9) {
            return 1;
        } else if (this.raw < 2.7) {
            return 2;
        } else if (this.raw < 3.5) {
            return 3;
        } else if (this.raw < 4.3) {
            return 4;
        } else {
            return 5;
        }        
    }
    
    getColor(item) {
        return item == this.getScore() ? this.domain.color: 'lightgrey';
    }
    
    getDisplayScore(item) {
        return item == this.getScore() ? this.raw: item;
    }
    
    getScoreText() {
        if (this.raw < 1.9) {
            return "Your score is between 1.0 and 1.8.";
        } else if (this.raw < 2.7) {
            return "Your score is between 1.9 and 2.6";
        } else if (this.raw < 3.5) {
            return "Your score is between 2.7 and 3.4";
        } else if (this.raw < 4.3) {
            return "Your score is between 3.5 and 4.2";
        } else {
            return "Your score is between 4.3 and 5.0";
        }
    }
    
    getIcon(item) {
        if (item == 1) {
            return html`
                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.getColor(1)}" fill="transparent" stroke-width="3" />
                </svg>`
        } else if (item == 2) {
            return html`
                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.getColor(2)}" fill="transparent" stroke-width="1" />
                    <polygon points="12,25 25,12 25,25" fill="${this.getColor(2)}"/>
                </svg>`
        } else if (item == 3) {
            return html`
                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <rect width="100%" height="100%" stroke="${this.getColor(3)}" fill="transparent" stroke-width="1" />
                  <polygon points="25,0 25,25 0,25" fill="${this.getColor(3)}"/>
                </svg>
            `;
        } else if (item == 4) {
            return html`
                <svg style="aspect-ratio: 1; width: 25px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${this.getColor(4)}" fill="transparent" stroke-width="1" />
                    <polygon points="0,12 0,25 25,25 25,0 12,0" fill="${this.getColor(4)}"/>
                </svg>    
            `
        } else if (item == 5) {
            return html`
                <div style="align: center; background-color: ${this.getColor(5)}; aspect-ratio: 1; width: 25px;"></div>              
            `
        }
    }
    
    render() {
        return html`
            ${this.profile ? html`
                ${this.getIcon(this.getScore())}
             `: html`
                <div style="border: 7px solid ${this.domain.color};">

                    <div style="display: grid; grid-template-columns: 1fr 5fr;">
                        <div><img height="100px;" src="./images/${this.domain.image}"></div>
                        <div>
                            <div style="font-size: 2.5em; margin-top: 15px; margin-bottom: 10px; color: ${this.domain.color};">
                                ${this.domain.name.toUpperCase()} DOMAIN
                            </div>
                            <div style="font-size: smaller; margin: 10px;">${this.domain.description}</div>
                        </div>
                    </div>

                    <div style="font-size: larger; margin-left: 40px; margin-bottom: 10px;">At work, you are</div>

                    <div style="width: 60%; margin-left: 40px;">
                        <div style="display: grid; grid-gap: 2px; grid-template-columns: 1fr 1fr 5fr;">

                            <div style="color: ${this.domain.color}; font-size: larger; font-weight: bolder;">${this.getScore()}</div>
                            <div style="font-size: larger;">${this.getIcon(this.getScore())}</div>
                            <div style="color: ${this.domain.color}; margin-bottom: 20px; font-size: larger; font-weight: bolder;">${this.domain.label[this.getScore() - 1]}</div>

                            <div style="color: ${this.getColor(5)};">${this.getDisplayScore(5)}</div>
                            <div style="color: ${this.getColor(5)};">${this.getIcon(5)}</div>
                            <div style="color: ${this.getColor(5)};">${this.domain.label[4]}</div>

                            <div style="color: ${this.getColor(4)};">${this.getDisplayScore(4)}</div>
                            <div style="color: ${this.getColor(4)};">${this.getIcon(4)}</div>
                            <div style="color: ${this.getColor(4)};">${this.domain.label[3]}</div> 

                            <div style="color: ${this.getColor(3)};">${this.getDisplayScore(3)}</div>
                            <div style="color: ${this.getColor(3)};">${this.getIcon(3)}</div>
                            <div style="color: ${this.getColor(3)};">${this.domain.label[2]}</div>

                            <div style="color: ${this.getColor(2)};">${this.getDisplayScore(2)}</div>
                            <div style="color: ${this.getColor(2)};">${this.getIcon(2)}</div>
                            <div style="color: ${this.getColor(2)};">${this.domain.label[1]}</div>

                            <div style="color: ${this.getColor(1)};">${this.getDisplayScore(1)}</div>
                            <div style="color: ${this.getColor(1)};">${this.getIcon(1)}</div>
                            <div style="color: ${this.getColor(1)};">${this.domain.label[0]}</div>

                        </div>
                    </div>

                    <div style="margin: 40px; font-size: smaller;">

                        <p>${this.domain.feedback[this.getScore() - 1]}</p>

                    </div>

                </div>
            `}
        `
    } 
 
}
if (!customElements.get('intrivity-domain')) customElements.define('intrivity-domain', IntrivityDomain);
